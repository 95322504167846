<template>
  <a-col :span="props.col">
    <div class="flex relative items-center justify-between bg-gray-50 w-[90%] p-3 pr-8 mt-2 rounded">
      <div class="flex items-center" style="width: 80%">
        <FileOutlined class="text-3xl bg-white p-2 rounded" style="border: 1px solid #d7d7d7; border-radius: 6px" />
        <div class="flex flex-col ml-4" style="width: 100%">
          <span style="overflow: hidden; width: 100%; text-overflow: ellipsis; white-space: nowrap">{{ file.name }}</span>
          <span>{{ sizeToShow }}</span>
        </div>
      </div>
      <Dropdown placement="top">
        <div class="ml-4 absolute right-0 h-full flex items-center justify-center w-8 cursor-pointer bg-gray-100">
          <DownOutlined />
        </div>
        <template #overlay>
          <Menu>
            <MenuItem v-for="fm in fileMenus" :key="fm.text" @click.stop="fm.onClick">{{
              fm.text
            }}</MenuItem>
          </Menu>
        </template>
      </Dropdown>
    </div>
  </a-col>
</template>

<script setup lang="ts">
import { FileOutlined, DownOutlined } from '@ant-design/icons-vue'
import { Dropdown, Menu, MenuItem } from 'ant-design-vue'
import type { UploadedItem } from '@elecmap/api/src/ram.d'
import { computed } from 'vue'
import http from '@elecmap/api/src/client'

type ApproveFileMode = 'preview' | 'upload'

const props = withDefaults(
  defineProps<{
    file: UploadedItem
    mode: ApproveFileMode
    col: number
  }>(),
  { mode: 'upload', col: 24 },
)

const emit = defineEmits<{
  (event: 'delete', file: UploadedItem): void
}>()

const formatSize = (val: number) => {
  const units = ['B', 'KB', 'MB', 'GB']
  let index = 0
  let size = val
  while (size.toFixed(0).length > 3 && index <= 4) {
    index += 1
    size /= 1024
  }
  return `${size.toFixed(2)}${units[index]}`
}

const sizeToShow = computed(() => formatSize(props.file.size))

const previewFile = () => {
  http(
    {
      method: 'GET',
      url: `/common/api/file/preview-file/${props.file.uuid}`,
    },
    false,
  )
}

const downloadFile = () => {
  http(
    {
      method: 'GET',
      url: `/common/api/file/download-file/${props.file.uuid}`,
    },
    true,
  )
}

const fileMenus = computed(() => {
  const ret = [{ text: '预览', onClick: previewFile }]
  if (props.mode === 'upload') {
    ret.push({ text: '删除', onClick: () => emit('delete', props.file) })
  } else {
    ret.push({ text: '下载', onClick: downloadFile })
  }
  return ret
})
</script>

<style lang="less">
.more-button {
  height: 100%;
}
</style>
