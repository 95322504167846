import http, { getUrl } from '../client'
import type { UploadFileResponse } from './file.d'

export function uploadFile(data: FileList) {
  const fd = new FormData()
  for (let i = 0; i < data.length; i += 1) {
    fd.append('fileList', data[i])
  }
  return http<UploadFileResponse[]>({
    url: '/cad/api/file/upload-file',
    data: fd,
  })
}

export function previewFile(uuid?: string) {
  if (!uuid) return ''
  return getUrl(`/cad/api/file/preview-file/${uuid}`)
}

export function getPreviewUrl(uuid: string) {
  return http<string>({
    url: `/cad/api/file/get-preview-url/${uuid}`,
    method: 'get',
  })
}
