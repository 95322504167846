import { ref, watch } from 'vue'
import type { Ref } from 'vue'

export function useStorageRef<T = any>(key: string, initial?: T) {
  let storeValue = localStorage.getItem(key)
  if (storeValue) {
    try {
      storeValue = JSON.parse(storeValue)
    } catch {
      storeValue = null
    }
  }
  const data = ref(storeValue ?? initial ?? '')

  watch(
    () => data.value,
    v => {
      const value = JSON.stringify(v)
      localStorage.setItem(key, value)
    },
    { deep: true },
  )

  return data as Ref<T>
}
