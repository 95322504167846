<template>
  <div class="folder-item" @click="emit('click')" tabindex="1">
    <Icon code="xe80c;" style="margin-left: 4px; color: #ffca28" :size="16"></Icon>
    <div class="item-name">{{ item.name }}</div>
    <Menu
      icon-class="iconfont2"
      :menus="folderMenus"
      @click="key => handleFolderMenuClick(key, item)"
    >
      <Icon
        class="more"
        tabindex="1"
        @click.stop=""
        code="xe719;"
        style="margin: 0 4px; cursor: pointer"
        :size="16"
      ></Icon>
    </Menu>
    <div v-if="showPath" class="item-path">{{ path }}</div>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from 'vue'
import { Modal, Menu } from '@elecmap/elecmap-ui'
import type { EmMenuItem } from '@elecmap/elecmap-ui/lib/menu/types'
import { Icon } from '@elecmap/icon'
import type { CadFolderShortVo } from '@elecmap/api/src/cad/space.d'

const props = defineProps<{
  item: CadFolderShortVo
  showPath?: boolean
}>()

const path = computed(() => {
  if (!props.showPath) return ''
  return props.item.parentFolderList?.map(pf => pf.name).join(' > ')
})

const emit = defineEmits<{
  (event: 'rename', payload: { id: number; name: string }): void
  (event: 'delete'): void
  (event: 'click'): void
  (event: 'move'): void
}>()

const folderMenus: EmMenuItem[][] = [
  [
    {
      name: '重命名',
      key: 'rename',
      icon: '&#xe63a',
    },
    {
      name: '移动',
      key: 'move',
      icon: '&#xe600',
    },
  ],
  [
    {
      name: '删除',
      key: 'delete',
      icon: '&#xe718',
    },
  ],
]

function handleRenameFolder(folder: CadFolderShortVo) {
  Modal.form({
    width: '520px',
    title: `重命名-${folder.name}`,
    formItems: [
      {
        label: '名称',
        placeholder: '请输入名称',
        type: 'Input',
        key: 'name',
        rules: [
          {
            required: true,
            message: '请输入名称',
          },
        ],
      },
    ],
    formState: reactive({ keywords: { ...folder }, tags: {} }),
    okText: '确认',
    onSubmit: ({ keywords }: any) => {
      emit('rename', {
        name: keywords.name,
        id: keywords.id,
      })
    },
  })
}

function handleDeleteFolder(folder: CadFolderShortVo) {
  Modal.confirm({
    title: `删除-${folder.name}`,
    content: [
      '请确认您要删除这个空间。',
      '这个空间内相关信息将会被永久删除。',
      '这是一个不可逆的操作，请谨慎对待。',
    ].join('<br />'),
    onOk: () => {
      emit('delete')
    },
  })
}

function handleFolderMenuClick(key: string, item: CadFolderShortVo) {
  switch (key) {
    case 'rename':
      handleRenameFolder(item)
      break
    case 'move':
      emit('move')
      break
    case 'delete':
      handleDeleteFolder(item)
      break
  }
}
</script>

<style lang="less">
.folder-item {
  cursor: pointer;
  width: 280px;
  height: 40px;
  // box-shadow: 1px 1px 2px 2px rgb(240, 240, 240);
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin-right: 36px;
  margin-bottom: 24px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 12px;
  transition: box-shadow 0.2s;
  position: relative;
  &:focus {
    border: 2px solid #268feb;
  }
  &:hover {
    box-shadow: 1px 1px 2px 2px rgb(240, 240, 240);
  }
  .item-name {
    flex: 1;
    margin-left: 4px;
    // font-size: 13px;
  }

  &.create {
    justify-content: center;
    color: #999;
    .text {
      margin-left: 4px;
    }
  }

  .more {
    transition: opacity 0.18s ease-in-out;
    opacity: 0;
  }

  &:hover {
    .more {
      opacity: 1;
    }
  }

  .more:focus {
    opacity: 1;
  }

  .item-path {
    position: absolute;
    bottom: -24px;
    left: 0px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.5);
  }
}
</style>
