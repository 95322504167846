<template>
  <Modal
    width="650px"
    :btns="modalBtns"
    wrapClassName="no-border"
    @open="handleOpen"
    @close="handleClose"
  >
    <template #title>
      <div class="flex justify-between items-center title-wrapper">
        <span class="title">{{ isAddMode ? '添加成员' : props.title }}</span>
        <Input v-if="!isAddMode" class="search-input" placeholder="搜索成员">
          <template #prefix>
            <search-outlined class="certain-category-icon" />
          </template>
        </Input>
      </div>
    </template>
    <template v-if="!isAddMode">
      <div class="manage-body">
        <div v-for="(s, index) in members" :key="s.userId" class="manage-item">
          <div class="manage-left">
            <div class="left-avatar"></div>
            <div class="left-name">{{ s.employeeName }}</div>
          </div>

          <a-dropdown
            :disabled="s.memberPermission === 'owner'"
            :trigger="['click']"
            :overlayClassName="'right-dropdown'"
            placement="bottomRight"
          >
            <div class="manage-right">
              <div :class="s.memberPermission !== 'owner' ? 'right-name' : 'right-first'">
                {{ getPermissionShortDesc(s.memberPermission) }}
              </div>
              <div class="right-icon" v-show="s.memberPermission !== 'owner'">▾</div>
            </div>
            <template #overlay>
              <a-menu @click="changeCurrentPermission($event, index)">
                <a-menu-item key="manager">
                  <div class="operate-name">可管理（可删除、编辑、下载、移动）</div>
                </a-menu-item>
                <a-menu-item key="editor">
                  <div class="operate-name">可编辑（可编辑、下载、移动）</div>
                </a-menu-item>
                <a-menu-item key="viewer">
                  <div class="operate-name">可查看（仅支持在线浏览）</div>
                </a-menu-item>
                <a-menu-item key="owner">
                  <div class="operate-name">设为拥有者</div>
                </a-menu-item>
                <a-menu-divider></a-menu-divider>
                <a-menu-item key="remove">
                  <div class="operate-name">移除</div>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="flex add-body">
        <div>
          <Input class="search-input" placeholder="搜索成员">
            <template #prefix>
              <search-outlined class="certain-category-icon" />
            </template>
          </Input>
          <div class="tree">
            <Tree
              checkable
              :selectable="false"
              default-expand-all
              :tree-data="orgMemberTree"
              :checked-keys="checkedKeys"
              @check="handleCheck"
            ></Tree>
          </div>
        </div>
        <div class="selected">
          <div class="selected-top">
            <div class="top-left">已选人员</div>
            <div class="clear-btn">全部清除</div>
          </div>
          <div v-for="s in selected" :key="s.id" class="manage-item">
            <div class="manage-left">
              <div class="left-avatar"></div>
              <div class="left-name">{{ s.employeeName }}</div>
            </div>

            <div class="manage-right">
              <a-dropdown
                :trigger="['click']"
                :overlayClassName="'right-dropdown'"
                placement="bottomRight"
              >
                <div class="flex">
                  <div :class="s.memberPermission !== 1 ? 'right-name' : 'right-first'">
                    {{ getPermissionShortDesc(s.memberPermission) }}
                  </div>
                  <div class="right-icon">▾</div>
                </div>
                <template #overlay>
                  <a-menu @click="(e: any) => (s.memberPermission = e.key)">
                    <a-menu-item key="manager">
                      <div class="operate-name">可管理（可删除、编辑、下载、移动）</div>
                    </a-menu-item>
                    <a-menu-item key="editor">
                      <div class="operate-name">可编辑（可编辑、下载、移动）</div>
                    </a-menu-item>
                    <a-menu-item key="viewer">
                      <div class="operate-name">可查看（仅支持在线浏览）</div>
                    </a-menu-item>
                    <!-- <a-menu-item key="owner">
                      <div class="operate-name">设为拥有者</div>
                    </a-menu-item> -->
                  </a-menu>
                </template>
              </a-dropdown>
              <div @click="removeFromSelected(s)" class="ml-4 iconfont">&#xe608;</div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup lang="ts">
import { Input, Tree, type TreeProps } from 'ant-design-vue'
import { SearchOutlined } from '@ant-design/icons-vue'
import type { CadFolderMemberVo, CadProjectShortVo } from '@elecmap/api/src/cad/space.d'
import { Modal } from '@elecmap/elecmap-ui'
import { computed, ref, shallowRef, useAttrs, watch } from 'vue'
import { getDepartmentTreeData, listDepartmentEmployeeTree } from '@elecmap/api/src/ram'
import type { Department, EmployeeVo } from '@elecmap/api/src/ram.d'
import type { MemberPermission } from '@elecmap/api/src/cad/shared.d'

const isAddMode = ref(false)

const props = defineProps<{
  title?: string
  members?: CadFolderMemberVo[]
}>()

const emit = defineEmits(['ok', 'transferOwner'])

const selected = ref<any[]>([])
const orgMemberTree = ref<Department[]>([])
const rawTree = shallowRef()

const modalBtns = computed<any[]>(() => {
  return !isAddMode.value
    ? [
        {
          type: 'link',
          text: '+添加成员',
          onClick: () => {
            isAddMode.value = true
          },
        },
      ]
    : [
        {
          type: 'primary',
          text: '确定',
          onClick: () => {
            handleOk()
            isAddMode.value = false
          },
        },
        {
          type: 'default',
          text: '关闭',
          onClick: () => {
            isAddMode.value = false
          },
        },
      ]
})

const shortMap: Record<MemberPermission, string> = {
  owner: '拥有者',
  manager: '可管理',
  editor: '可编辑',
  viewer: '可查看',
}

function getPermissionShortDesc(p: MemberPermission) {
  return shortMap[p]
}

const checkedKeys = computed(() => selected.value.map(item => item.id))

const handleCheck: TreeProps['onCheck'] = (keys, e) => {
  selected.value = e.checkedNodes.map(item => ({ ...item, memberPermission: 'editor' }))
}

// const treeData: TreeProps['treeData'] = [
//   {
//     title: 'parent 1',
//     key: '0-0',
//     checkable: false,
//     children: [
//       {
//         title: 'leaf4',
//         key: '0-0-0',
//       },
//       { title: 'leaf1', key: '0-0-0-0' },
//       { title: 'leaf2', key: '0-0-0-1' },
//     ],
//   },
//   {
//     title: 'parent 2',
//     key: '2',
//     checkable: false,
//     children: [{ key: '2-1', title: 'leaf3' }],
//   },
// ]

function walk(treeData: Department[]) {
  const tree = [] as Department[]
  treeData.forEach(item => {
    const ret = { ...item } as any
    const children = item.children?.map(item => ({
      ...item,
      key: item.id,
      title: item.departmentName,
      checkable: false,
    }))
    const employees = item.employeeVOList?.map(item => ({
      ...item,
      key: item.id,
      title: item.employeeName,
      disabled: props.members?.some(m => m.employeeId === item.id),
    }))
    ret.children = [] as any
    ret.employeeVOList = []
    if (children) {
      const c = walk(children)
      ret.children = c
    } else {
      ret.children = []
    }
    if (employees) {
      ret.children?.push(...employees)
    }
    tree.push(ret)
  })
  return tree
}

async function handleOpen() {
  const data = await listDepartmentEmployeeTree()
  rawTree.value = data.departmentTreeList.map(item => ({
    ...item,
    key: item.id,
    title: item.departmentName,
    checkable: false,
  }))
  const tree = walk(rawTree.value)
  orgMemberTree.value = tree
}

function removeFromSelected(s: CadFolderMemberVo) {
  selected.value = selected.value.filter(s1 => s1.userId !== s.userId)
}

function changeCurrentPermission(e: any, index: number) {
  if (props.members) {
    if (e.key === 'owner') {
      emit('transferOwner', {
        userId: props.members[index].userId,
        employeeId: props.members[index].employeeId,
      })
      return
    }
    if (e.key === 'remove') {
      const id = props.members[index].id
      const newMembers = props.members.filter(m => m.id !== id)
      emit('ok', newMembers)
      return
    }
    props.members[index].memberPermission = e.key
    emit('ok', props.members)
  }
}

function handleClose() {
  selected.value = []
  isAddMode.value = false
}

function handleOk() {
  emit('ok', [...(props.members ?? []), ...selected.value])
  selected.value = []
}

watch(
  () => props.members,
  () => {
    if (rawTree.value) {
      orgMemberTree.value = walk(rawTree.value)
    }
  },
)
</script>

<style lang="less" scoped>
.title {
  font-size: 15px;
}

.title-wrapper {
  padding-right: 40px;
  height: 32px;

  .search-input {
    width: 200px;
    border-radius: 20px;
    background-color: #fbfbfb;

    :deep(.ant-input) {
      background-color: #fbfbfb;
    }
  }
}

.manage-body {
  height: 400px;

  .manage-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    .manage-left {
      display: flex;
      align-items: center;

      .left-avatar {
        width: 40px;
        height: 40px;
        background-color: rgb(0, 112, 221);
        border-radius: 50%;
        margin-right: 12px;
      }

      .left-name {
        font-size: 13px;
      }
    }

    .manage-right {
      display: flex;
      align-items: center;
      cursor: pointer;

      .right-first {
        margin-right: 10px;
      }
    }
  }
}

.add-body {
  border: 1px solid #f0f0f0;
  height: 400px;

  .search-input {
    width: 264px;
    border-radius: 20px;
    background-color: #fbfbfb;
    margin: 12px 0 12px 4px;

    :deep(.ant-input) {
      background-color: #fbfbfb;
    }
  }

  .tree {
    height: 360px;
    overflow: auto;
  }

  > div {
    flex: 1 0 0;
  }

  > .selected {
    border-left: 1px solid #f0f0f0;
    padding: 16px;

    .selected-top {
      display: flex;
      justify-content: space-between;
      margin-top: 2px;
      margin-bottom: 20px;

      .top-left {
        font-size: 11px;
      }

      .clear-btn {
        font-size: 11px;
        color: rgb(0, 112, 221);
        cursor: pointer;
      }
    }

    .manage-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      .manage-left {
        display: flex;
        align-items: center;

        .left-avatar {
          width: 28px;
          height: 28px;
          background-color: rgb(0, 112, 221);
          border-radius: 50%;
          margin-right: 12px;
        }

        .left-name {
          font-size: 13px;
        }
      }

      .manage-right {
        display: flex;
        align-items: center;
        cursor: pointer;

        .right-first {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
