const common = {
  action: {
    add: '添加',
    agree: '同意',
    approve: '通过',
    batchImport: '批量导入', // TODO: remove
    create: '新建',
    clickToChooseFile: '点击选择文件',
    confirm: '确定',
    delete: '删除',
    disable: '停用',
    enable: '启用',
    download: '下载',
    downloadTemplate: '下载模板',
    freeze: '冻结',
    logout: '退出',
    edit: '编辑',
    reject: '拒绝',
    remove: '移除',
    recall: '撤回',
    release: '发布',
    reviewPass: '审核通过',
    revoke: '撤回',
    submit: '提交',
    submitReview: '提交审核',
    revertReview: '撤回',
    submitPublishState: '启用',
    turnDown: '驳回',
    upload: '上传',
    view: '查看',
    yes: '是', // TODO: remove
    no: '否', // TODO: remove
    copy: '复制',
    cancel: '取消',
    modify: '修改',
    save: '保存',
    batchAction: '批量操作',
    saveAndConfig: '保存并配置',
    saveAndSubmitReview: '保存并提交审核',
    saveOnly: '仅保存',
    update: '更新',
    unfreeze: '恢复',
    have: '有',
    notHave: '无',
    bind: '绑定',
    viewApproval: '查看审批',
    batch: '批量{action}',
    close: '关闭',
    recover: '恢复',
    return: '返回',
    review: '审核',
    reverseReview: '反审核',
    end: '结束',
    export: '导出',
    more: '更多',
    reset: '重置',
    set: '设置',
    preview: '预览',
  },
  field: {
    account: '账号',
    approvalStatus: '审核状态',
    serialNumber: '序号',
    createdBy: '创建人',
    createdAt: '创建时间',
    updatedBy: '更新人',
    updatedAt: '更新时间',
    operation: '操作',
    uploadedAt: '上传时间',
    uploadedBy: '上传人',
    organization: '企业',
    status: '状态',
    description: '描述',
    user: '用户',
    link: '链接',
    loginAccount: '登录账号',
    name: '姓名',
    verificationCode: '验证码',
    gender: '性别',
    email: '邮箱',
    phone: '手机号',
    remark: '备注',
    releaseStatus: '启用状态',
    woman: '女',
    man: '男',
    username: '用户名',
    page: '页面',
    password: '密码',
    button: '按钮',
    tab: '页签',
    fax: '传真',
    up: '上',
    down: '下',
    front: '前',
    behind: '后',
    left: '左',
    right: '右',
    version: '版本',
    approverName: '审核人',
    approvedTime: '审核时间',
    yuan: '元',
  },
  hint: {
    input: '请输入{field}',
    searchAndSelect: '请搜索并选择{field}',
    select: '请选择{field}',
    upload: '请上传{field}',
    orDragFileHere: '或拖拽文件至此',
  },
  message: {
    createdSuccessfully: '新建{field}{name}成功',
    copiedToClipboard: '{field}已复制到剪贴板',
    deleteConfirm: '确定要删除{field}“{name}”吗？删除后将不可恢复！',
    deletedSuccessfully: '删除{field}{name}成功',
    error401: '请登录',
    error403: '您的权限不足',
    operatedSuccessfully: '操作成功',
    updatedSuccessfully: '更新{field}{name}成功',
    uploadFailed: '{field}上传失败',
    commonRequestFailed: '系统错误，请稍后重试',
    registerSucceed: '注册成功,请登录',
    maxUploadCount: '最多上传 {field} 个文件',
    updateSuccessfully: '修改成功',
    quantityIntegerInfo: '请输入正整数',
  },
  status: {
    all: '全部',
    approvalPending: '待审核',
    approvePass: '审核通过',
    approveReject: '审核驳回',
    disabled: '已停用',
    draft: '草稿',
    normal: '正常',
    unreleased: '未启用',
    released: '已启用',
    unverified: '未认证',
    verified: '已认证',
    waitJoin: '待加入',
    rejectJoin: '拒绝加入',
    finished: '已完成',
    unfinished: '未完成',
    cancelled: '已取消',
    refunded: '已退款',
    partRefunded: '部分退款',
  },
  option: {
    female: '女',
    male: '男',
    no: '否',
    unknown: '未知',
    yes: '是',
    singleText: '单行文本',
    muchText: '多行文本',
    number: '数字',
    date: '日期',
    singleInput: '单选框',
    muchInput: '多选框',
    day: '天',
    hour: '小时',
    wechat: '微信',
    Alipay: '支付宝',
    balance: '账户余额',
  },
  confirmDelete: {
    title: '删除 {type} {name}',
    mainContent: '是否确认删除 {name} {type}',
    confirmContent: '删除后无法恢复，是否确认？',
    titleBatch: '批量删除 {type}',
    mainContentBatch: '是否确认批量删除 {type}',
  },
}

export default common
