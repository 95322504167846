<template>
  <div class="main">
    <div class="breadcrumb">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item class="cursor-pointer">“{{ props.keyword }}”搜索结果</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="folder" v-if="folders.length">
      <div class="folder-title">文件夹</div>
      <div class="flex flex-wrap">
        <FolderItem
          v-for="item in folders"
          :key="item.id"
          :item="item"
          show-path
          @click="handleFolderClick(item)"
        />
      </div>
    </div>
    <div class="project" v-if="projects.length">
      <div class="project-title">项目</div>
      <div class="flex flex-wrap">
        <ProjectItem
          v-for="item in projects"
          :key="item.id"
          :item="item"
          show-path
          @star="starProject(item)"
        />
        <!-- <div class="project-item" @click="toDesign(item)">
          <div class="preview" v-html="item.graphicalPreview"></div>
          <Menu
            icon-class="iconfont2"
            :menus="projectMenus"
            @click="key => handleProjectMenuClick(key, item)"
          >
            <Icon code="xe719;" class="project-code" :size="18" @click.stop=""></Icon>
          </Menu>
          <div class="item-name">{{ item.name }}</div>
        </div> -->
        <!-- <Icon
          code="xe84f;"
          style="margin: 0 12px; cursor: pointer"
          :size="36"
          @click="createProjectVisible = true"
        ></Icon> -->
      </div>
    </div>
  </div>

  <FolderTreeSelector
    v-model:visible="folderSelectorVisible"
    :title="`移动-${itemToMove?.name}`"
    @ok="handleMoveFolder"
  ></FolderTreeSelector>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import {
  changeProjectFavorite,
  deletePersonalProject,
  movePersonalProject,
  updatePersonalProject,
} from '@elecmap/api/src/cad/project'
import type { CadFolderShortVo, CadProjectShortVo } from '@elecmap/api/src/cad/space.d'
import FolderTreeSelector from '@/components/FolderTreeSelector.vue'
import ProjectItem from '@/components/ProjectItem.vue'
import FolderItem from '@/components/FolderItem.vue'
import { listFolderAndProjectByName, movePersonalFolder } from '@elecmap/api/src/cad/space'
import { useSpaceStore } from '@/store/space'
import { useRouter } from 'vue-router'
import usePersonalSpaceStore from '@/store/personal-space'

const props = defineProps(['keyword'])

const emit = defineEmits(['clear'])

const folders = ref<CadFolderShortVo[]>([])
const projects = ref<CadProjectShortVo[]>([])
const folderSelectorVisible = ref(false)

async function listProjects() {
  if (props.keyword) {
    const res = await listFolderAndProjectByName({ name: props.keyword })
    folders.value = res.subFolderList ?? []
    projects.value = res.projectList ?? []
  } else {
    folders.value = []
    projects.value = []
  }
}

const itemToMove = ref<CadFolderShortVo | CadProjectShortVo>()

function moveProject(project: CadProjectShortVo) {
  folderSelectorVisible.value = true
  itemToMove.value = project
}

async function handleMoveFolder(parentId: number) {
  if (!itemToMove.value) return
  if ((itemToMove.value as CadProjectShortVo).storageLocation) {
    await movePersonalProject({
      id: itemToMove.value.id,
      storageLocation: parentId,
    })
  } else {
    await movePersonalFolder({
      id: itemToMove.value.id,
      parentId,
    })
  }
  folderSelectorVisible.value = false
  listProjects()
}

async function handleRenameProject(data: any) {
  await updatePersonalProject(data)
  listProjects()
}

async function handleDeleteProject(id: number) {
  await deletePersonalProject(id)
  listProjects()
}

async function starProject(p: CadProjectShortVo) {
  p.favoriteFlag = !p.favoriteFlag
  await changeProjectFavorite(p.id, p.favoriteFlag)
}

const router = useRouter()

function handleFolderClick(f: CadFolderShortVo) {
  const spaceType = f.parentFolderList![0].type
  const navPath = [...f.parentFolderList!, f]
  const spaceStore = useSpaceStore()
  if (spaceType === '0') {
    router.push({
      name: 'personal',
      params: {
        path: JSON.stringify(navPath),
      },
    })
    spaceStore.currentSpace = undefined
    usePersonalSpaceStore().navs = navPath
  } else {
    spaceStore.navs = navPath
    router.push({
      path: '/workbench/index',
    })
  }
  emit('clear')
}

listProjects()

watch(
  () => props.keyword,
  () => listProjects(),
)
</script>

<style>
.folder-popover {
  .item-line {
    margin: 0px auto 8px;
    height: 1px;
    background-color: #ddd;
  }

  .item-operate {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    cursor: pointer;

    .operate-name {
      font-size: 12px;
      margin-left: 12px;
    }
  }
}

.project-popover {
  .item-line {
    margin: 0px auto 8px;
    height: 1px;
    background-color: #ddd;
  }

  .item-operate {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    cursor: pointer;

    .operate-name {
      font-size: 12px;
      margin-left: 12px;
    }
  }
}

.folder-dropdown {
  .ant-dropdown-content {
    width: 140px;

    .ant-menu-item-divider {
      transform: scaleX(0.86);
    }
  }
}

.project-dropdown {
  .ant-dropdown-content {
    width: 140px;

    .ant-menu-item-divider {
      transform: scaleX(0.86);
    }
  }
}
</style>

<style scoped lang="scss">
.main {
  margin-left: 24px;

  .folder {
    margin-top: 36px;

    .folder-title {
      font-size: 13px;
      margin-bottom: 18px;
      color: rgb(153, 153, 153);
    }

    .folder-content {
      display: flex;
    }
  }

  .project {
    margin-top: 36px;

    .project-title {
      font-size: 13px;
      margin-bottom: 18px;
      color: rgb(153, 153, 153);
    }
  }
}
</style>
