<template>
  <section class="flex w-full h-100">
    <nav class="nav">
      <div class="main">
        <UserDropdown :is-enterprise="true">
          <div class="head-content">
            <div class="item-avatar">
              <Avatar
                :size="24"
                class="editImg mx-[4px]"
                :name="currentOrganization?.organizationName"
                :url="previewFile(currentOrganization?.logo)"
              >
              </Avatar>
            </div>
            <div class="font-bold text-[16px]">
              {{ currentOrganization?.organizationName }}
            </div>
          </div>
        </UserDropdown>
        <div class="content">
          <div class="item-content" @click="goto('/settings/member')">
            <Icon code="xe7ea;" style="margin-right: 14px" :size="16"></Icon>
            <div class="item-name">成员管理</div>
          </div>
          <!-- <div class="item-content" @click="goto('/settings/member')">
            <Icon code="xe623;" style="margin-right: 14px" :size="16"></Icon>
            <div class="item-name">席位管理</div>
          </div> -->
          <div class="item-content" @click="goto('/settings/space')">
            <Icon code="xe60f;" style="margin-right: 14px" :size="16"></Icon>
            <div class="item-name">空间管理</div>
          </div>
          <div class="item-content" @click="goto('/settings/project')">
            <Icon code="xe617;" style="margin-right: 14px" :size="16"></Icon>
            <div class="item-name">项目管理</div>
          </div>
          <div class="item-content" @click="goto('/settings/enterprise')">
            <Icon code="xe7ea;" style="margin-right: 14px" :size="16"></Icon>
            <div class="item-name">企业设置</div>
          </div>
        </div>
        <div class="bottom-line"></div>
        <div class="bottom">
          <div class="bottom-left" @click="goto('/workbench')">
            <Icon code="xe613;" :gutter="4" :size="16"> </Icon>
            <div class="left-name">返回工作台</div>
          </div>
        </div>
        <!-- <RouterLink to="/workbench">返回工作台</RouterLink> -->
      </div>
    </nav>
    <main class="main w-full flex flex-col">
      <UserBar>
        <!-- <template #extra>
          
        </template> -->
      </UserBar>
      <div style="padding: 16px; padding-top: 0; flex: 1">
        <RouterView></RouterView>
      </div>
    </main>
  </section>
</template>

<script setup lang="ts">
import { RouterView, RouterLink } from 'vue-router'
import { Icon } from '@elecmap/icon'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import useUserStore from '@/store/user'
import UserBar from '../components/user-bar.vue'
import { Avatar } from '@elecmap/elecmap-ui'
import { useOrganization } from '@/store/organization'
import { previewFile } from '@elecmap/api/src/cad/file'
import UserDropdown from '@/components/user-dropdown.vue'

const router = useRouter()
const { userInfo } = storeToRefs(useUserStore())
const { currentOrganization } = storeToRefs(useOrganization())

function goto(path: string) {
  router.push({ path })
}
</script>

<style scoped lang="scss">
.nav {
  flex: 0 0 225px;
  position: relative;
  .main {
    // margin: 15px 18px 0;
    padding: 24px 16px 0;
  }
}

.main {
  .head-content {
    display: flex;
    align-items: center;
  }

  .content {
    margin-top: 24px;

    .item-content {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      cursor: pointer;

      .item-name {
        font-size: 13px;
      }
    }
  }

  .bottom-line {
    position: absolute;
    bottom: 32px;
    width: 196px;
    height: 1px;
    transform: translateX(-8px);
    background-color: #ddd;
  }

  .bottom {
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translateX(-55%);
    display: flex;
    justify-content: center;
    align-items: center;
    // border-top: 1px #ddd solid;

    .bottom-left {
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;

      .left-name {
        // margin-left: 4px;
      }
    }

    .bottom-middle {
      height: 20px;
      width: 1px;
      background-color: #bbb;
    }

    .bottom-right {
      display: flex;
      cursor: pointer;
    }
  }
}
</style>
