import { message, Spin } from 'ant-design-vue'
import axios from 'axios'
import type { AxiosResponse, AxiosRequestConfig } from 'axios'
import { createApp, nextTick } from 'vue'
import { isDev } from './env'

type HttpConfig = AxiosRequestConfig & { proxyEnv?: string; loading?: boolean }

message.config({ maxCount: 1 })

const env = import.meta.env.VITE_API_ENV
let requestCount = 0
const prevent = (e: Event) => e.stopImmediatePropagation()
const loading = () => {
  if (requestCount === 0) {
    nextTick().then(() => {
      const dom = document.createElement('div')
      dom.setAttribute('id', 'loading')
      dom.addEventListener('mousedown', prevent)
      dom.addEventListener('touchstart', prevent)
      dom.style.position = 'fixed'
      dom.style.width = '100vw'
      dom.style.height = '100vh'
      document.body.appendChild(dom)
      createApp(Spin).mount(dom)
    })
  }
  requestCount += 1
}
const hideLoading = () => {
  requestCount -= 1
  const loadingDom = document.getElementById('loading')
  if (requestCount === 0 && loadingDom) {
    document.body.removeChild(loadingDom)
  }
}

const triggerDownload = (url: string, filename = '') => {
  const link = document.createElement('a')
  link.style.display = 'none'
  // a 标签的 download 属性就是下载下来的文件名
  link.download = filename as string
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const commonHeader = () => ({
  'x-requested-with': 'XMLHttpRequest',
  Authorization: localStorage.getItem('Authorization') || false,
})

export const preview = (uuid: string) => {
  return `/common/api/file/preview-file/${uuid}`
}

export function getUrl(url: string) {
  return isDev ? `/${env}${url}` : url
}
/**
 * 请求
 * @param isDownload 只有响应类型是Blob时使用，true: 下载 false: 预览 undefined(不传): 按常规接口处理
 */
const http: <T>(config: HttpConfig, isDownload?: boolean) => Promise<T> = (config, isDownload) => {
  return new Promise((resolve, reject) => {
    config.validateStatus = status => {
      return status < 500
    }
    config.headers = {
      ...commonHeader(),
      ...config.headers,
    }

    config.baseURL = isDev ? `/${env}` : '/'
    if (config.proxyEnv) {
      delete config.proxyEnv
    }
    if (!config.method) config.method = 'post'
    if (isDownload !== undefined) {
      config.responseType = 'blob'
      config.loading = false
    }
    if (config.loading !== false) {
      loading()
    }
    axios(config)
      .then(async (response: AxiosResponse) => {
        const { headers } = response
        const res = response.data
        if (response.status === 200) {
          if (isDownload !== undefined && res instanceof Blob) {
            if (headers['content-type']?.includes('json')) {
              response.data.text().then((data: string) => {
                try {
                  message.error(JSON.parse(data).message)
                } catch {
                  reject()
                }
              })
              return
            }
            const mimeType = headers['content-type'] ?? 'application/pdf'
            const objectURL = window.URL.createObjectURL(new Blob([res], { type: mimeType }))
            if (isDownload) {
              const filename = decodeURIComponent(
                headers['content-disposition']?.split(';')[1].split('=')[1] as string,
              )
              triggerDownload(objectURL, filename)
            } else {
              window.open(objectURL)
            }
            // 释放的 URL 对象以及移除 a 标签
            URL.revokeObjectURL(objectURL)
            resolve(res as any)
            return
          }

          if (typeof res === 'object') {
            if (env === 'mock') {
              resolve(res.data)
              return
            }
            if (res.code === 200) {
              resolve(res.data)
              return
            }
            if (res.code === 10203002) {
              // eslint-disable-next-line no-console
              console.log('没权限：', config.url)
            }
            // 未登录或登录信息失效
            if (res.code === 10203001 || res.code === 10203003) {
              let params = ''
              if (res?.data?.isRedirectToOriginalPage) {
                params = `?redirectUrl=${encodeURIComponent(window.location.href)}`
              }
              if (window.location.pathname === '/design/') {
                window.location.href = `/#/login`
              } else {
                window.location.replace(`/#/login${params}`)
              }
              // window.location.reload()
              return
            }
          }
        }
        if (headers.redirect_url) {
          window.location.href = headers.redirect_url
        }
        const errorRes = {
          message: '请求错误',
          ...(res || {}),
        }
        message.error(errorRes.message)
        reject(response)
      })
      .catch(response => {
        message.error(response?.data?.message || '请求错误')
      })
      .finally(() => {
        if (config.loading !== false) {
          hideLoading()
        }
      })
  })
}

export const postExport = (url: string, data: any, isDownload = true) => {
  return http(
    {
      url,
      data,
    },
    isDownload,
  )
}

export default http
