import { defineStore } from 'pinia'
import { useStorageRef } from '@elecmap/shared/src/composable/use-storage-ref'
import { computed } from 'vue'
import type { ResponseData } from '../../../api/src/ram.d'
import { logout as doLogout } from '@elecmap/api/src/ram'
import { useSpaceStore } from './space'
import {useUser} from '@elecmap/shared/src/composable/use-user'

const userStore = defineStore('userStore', () => {
  const userInfo = useStorageRef('userInfo')
  const showInitialModal = useStorageRef('showInitialModal', false)
  const isPlatformUser = computed(() => userInfo.value?.organizationType === 'platform')
  const notPersonal = computed(() => userInfo.value?.organizationType !== 'personal')

  const isLogin = computed(() => !!userInfo.value?.userId)
  function setUser(data: any) {
    userInfo.value = data
    localStorage.setItem('userInfo', JSON.stringify(data))
    useSpaceStore().navs = []
  }

  function deleteUser() {
    setUser({} as ResponseData)
  }

  async function logout() {
    await doLogout({ token: userInfo.value!.token! })
    deleteUser()
    const space = useSpaceStore()
    space.navs = []
    return
  }

  return {
    userInfo,
    isLogin,
    showInitialModal,
    setUser,
    deleteUser,
    logout,
    isPersonalOrg: computed(() => userInfo.value?.organizationType === 'personal'),
    isPlatformUser,
    notPersonal,
  }
})

export default userStore
