<template>
  <Modal
    width="850px"
    :btns="feedbackBtn"
    :title="feedbackRecord ? '提交反馈' : '反馈记录'"
    class="feedback-modal"
  >
    <div v-if="feedbackRecord" class="feedback-record" @click="changeRecordState">反馈记录</div>
    <div v-else class="feedback-record" @click="changeRecordState">去提交反馈</div>
    <div class="feedback">
      <a-form
        v-if="feedbackRecord"
        autoComplete="off"
        :model="feedback"
        labelAlign="left"
        ref="feedbackRef"
        :labelCol="{ span: 4 }"
      >
        <a-row :gutter="32">
          <a-col :span="12">
            <a-form-item label="反馈类型" name="type" :labelCol="{ span: 4 }" :rules="[{ required: true, message: '请选择反馈类型' }]">
              <a-select ref="select" v-model:value="feedback.type" style="width: 100%">
                <a-select-option value="feature">功能优化</a-select-option>
                <a-select-option value="bug">bug反馈</a-select-option>
                <a-select-option value="consult">使用咨询</a-select-option>
                <a-select-option value="cooperation">商务合作</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="功能分类" name="type" :labelCol="{ span: 4 }" :rules="[{ required: true, message: '请选择功能分类' }]">
              <a-select ref="select" v-model:value="feedback.feature" style="width: 100%">
                <a-select-option value="workbench">工作台</a-select-option>
                <a-select-option value="design">设计中心</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item
          class="contract"
          label="您对Electrical ACE有哪些建议?"
          :colon="false"
          :labelCol="{ span: 18 }"
          required
        >
        </a-form-item>
        <a-form-item
          class="contract"
          label=""
          name="reason"
          :rules="[{ required: true, message: '请输入您的建议' }]"
        >
          <a-textarea placeholder="请输入您的建议" v-model:value="feedback.reason" rows="6" />
        </a-form-item>
        <a-form-item :label="'上传附件'" name="file" style="margin-bottom: 0">
          <a-upload
            v-model:file-list="feedback.file"
            name="file"
            :customRequest="customRequest"
            :showUploadList="false"
            :headers="commonHeader()"
            :multiple="true"
            action="https://dev.pearlcad.com/common/api/file/upload-file"
            :before-upload="beforeUpload"
          >
            <a-button>
              <PaperClipOutlined />
              <span>上传附件</span>
            </a-button>
          </a-upload>
        </a-form-item>

        <a-form-item :colon="false" :label="'  '" style="margin-top: 10px; margin-bottom: 0">
          支持格式png、jpg、gif、jpeg、pdf、doc，单个文件不超过15MB。
        </a-form-item>
        <a-form-item v-if="fileList.length" :colon="false" :label="'  '" style="margin-top: 0">
          <a-row>
            <FeedbackFile
              v-for="f in fileList"
              :col="12"
              :key="f.uuid"
              :file="f"
              @delete="(f: any) => (fileList = fileList.filter(f1 => f1.uuid !== f.uuid))"
            ></FeedbackFile>
          </a-row>
        </a-form-item>
      </a-form>
      <BasicGrid
        v-else
        id="feedback-record-grid"
        :columnDefs="columnDefs"
        :rowData="[]"
      ></BasicGrid>
    </div>
  </Modal>
</template>

<script setup lang="ts">
import { reactive, ref, useAttrs, watch } from 'vue'
import { PaperClipOutlined } from '@ant-design/icons-vue'
import { BasicGrid, Modal } from '@elecmap/elecmap-ui'
import type { IKeywords } from '@elecmap/elecmap-ui/lib/form/types'
import http from '@elecmap/api/src/client'
import type { UploadedResponse } from '@elecmap/api/src/ram.d'
import { pick } from 'lodash'
import { commonHeader } from '@elecmap/api/src/client'
import type { IModalBtnItem } from '@elecmap/elecmap-ui/lib/modal/types'
import { message } from 'ant-design-vue'
import FeedbackFile from './feedback-file.vue'

const feedbackRecord = ref(true)
const feedback = ref<IKeywords>({
  type: 'feature',
  feature: 'workbench'
})
const feedbackRef = ref()
const attrs = useAttrs() as any
const emit = defineEmits(['cancel'])
const fileList = ref<any[]>([])

watch(
  () => fileList.value.length,
  () => {
    feedbackRef.value?.validateFields(['file'])
    feedback.value.file = fileList.value
  },
  { deep: true },
)
const formatSize = (val: number) => {
  const units = ['B', 'KB', 'MB', 'GB']
  let index = 0
  let size = val
  while (size.toFixed(0).length > 3 && index <= 4) {
    index += 1
    size /= 1024
  }
  return `${size.toFixed(2)}${units[index]}`
}
const customRequest = (data: { file: File }) => {
  const formData = new FormData()
  formData.append('fileList', data.file)
  http<UploadedResponse>({ url: '/common/api/file/upload-file', data: formData })
    .then(file => {
      const row = file[0]
      if (row) {
        const document = {
          ...pick(row, 'uuid', 'name', 'format', 'size'),
        }
        // document.size = formatSize(document.size) as unknown as number
        fileList.value = [...fileList.value, document]
      } else {
        message.error('上传失败')
      }
    })
    .catch(() => {
      //
    })
}
const beforeUpload = (file: any) => {
  let a = true
  const isJpgOrPng = ['jpg', 'jpeg', 'png', 'pdf'].includes(file.type.split('/')[1])

  if (!isJpgOrPng) {
    message.error('请选择正确格式的文件')
    a = false
  } else {
    a = true
  }
  return a
}

const changeRecordState = () => {
  feedbackRecord.value = !feedbackRecord.value
}

const feedbackBtn = reactive<IModalBtnItem[]>([
  {
    key: 'saveAndConfig',
    text: '提交',
    type: 'primary',
    onClick: () => {
      feedbackRef.value.validate().then(() => {
        //
      })
    },
  },

  {
    key: 'cancel',
    text: '取消',
    type: 'default',
    onClick: () => {
      emit('cancel')
    },
  },
])

const columnDefs = reactive([
  {
    headerName: '序号',
    pinned: 'left',
  },
  {
    field: 'name',
    headerName: '反馈类型',
    width: 100,
  },
  {
    field: 'name',
    headerName: '功能分类',
    width: 100,
  },
  {
    field: 'name',
    headerName: '建议内容',
    width: 320,
  },
  {
    field: 'name',
    headerName: '提交时间',
    width: 100,
  },
  {
    field: 'name',
    headerName: '状态',
    width: 80,
  },
])
watch(
  () => attrs.visible,
  () => {
    if (attrs.visible) {
      feedback.value = {}
      fileList.value = []
    }
  },
)
</script>
<style>
.ant-modal-wrap .feedback-modal .ant-modal-content .ant-modal-body {
  overflow: hidden;
}

.feedback-modal {
  position: relative;

  .feedback-record {
    position: absolute;
    right: 70px;
    top: 20px;
    color: rgb(0, 112, 221);
    cursor: pointer;
  }
}
</style>
<style scoped lang="less">
.feedback {
  height: 280px;
}
</style>
