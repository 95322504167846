<template>
  <div class="user-bar flex justify-between items-center">
    <div class="extra">
      <slot name="extra">
        <div style="font-size: 18px;" v-if="title">{{ title }}</div>
      </slot>
    </div>

    <div class="flex items-center justify-end">
      <InviteButton v-if="!isPersonalOrg" :blue="true" />
      <UserDropdown></UserDropdown>
    </div>
    <!-- <Dropdown :trigger="['click']">
      <div class="avatar"></div>
      <template #overlay>
        <a-menu @click="handleMenuClick">
          <a-menu-item key="0">
            <template #icon>
              <Icon code="xe612;" style="margin:0 12px; cursor: pointer" :size="32"></Icon>
            </template>
            <div class="operate-name">开始设计</div>
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item key="logout"> 退出登录 </a-menu-item>
        </a-menu>
      </template>
    </Dropdown> -->
  </div>
  <Modal v-model:visible="createVisible" title="创建企业" @ok="handleOkCreate">
    <a-form
      labelAlign="left"
      ref="createFormRef"
      :labelCol="{ span: 4 }"
      :wrapperCol="{ span: 20 }"
      :model="createForm"
    >
      <BasicFormItem
        v-for="item in createDefs"
        :key="item.key"
        :item="item"
        v-model:value="createForm[item.key]"
      />
    </a-form>
  </Modal>
</template>

<script setup lang="ts">
import { Dropdown } from 'ant-design-vue'
import { useRoute, useRouter } from 'vue-router'
import { CheckOutlined } from '@ant-design/icons-vue'
import type { MenuInfo } from 'ant-design-vue/lib/menu/src/interface'
import { Icon } from '@elecmap/icon'
import { computed, onMounted, reactive, ref } from 'vue'
import { Avatar, Modal, BasicFormItem } from '@elecmap/elecmap-ui'
import { listOrganization, changeOrganization } from '@elecmap/api/src/ram'
import { useOrganization } from '@/store/organization'
import useUserStore from '@/store/user'
import { storeToRefs } from 'pinia'

import { generateInvitedToken } from '@elecmap/api/src/ram'

import InviteModal from '../views/member-manage/invite-modal.vue'
import InviteButton from './InviteButton.vue'
import type { IFormItem, IKeywords } from '@elecmap/elecmap-ui/lib/form/types'
import { useSpaceStore } from '@/store/space'
import { previewFile } from '@elecmap/api/src/cad/file'
import { useNavTab } from '@/store/navTab'
import UserDropdown from './user-dropdown.vue'

const userStore = useUserStore()
const router = useRouter()
const route = useRoute()

const title = computed(() => route.meta.title)

const { setUser } = useUserStore()
const { userInfo, isPersonalOrg } = storeToRefs(useUserStore())
const store = useOrganization()
const { setCurrentOrganizationId } = store
const { organization, organizationId } = storeToRefs(store)

const goChangeOrganization = (id: number) => {
  popoverVisible.value = false
  organizationPopoverVisible.value = false
  changeOrganization(id).then(res => {
    localStorage.removeItem('organizationId')
    setCurrentOrganizationId(id, res.organizationType)
    setUser(res)
    localStorage.setItem('refresh', '1')
    useSpaceStore().navs = []
    window.location.replace('/workbench')
    window.location.reload()
  })
}

const popoverVisible = ref(false)
const organizationPopoverVisible = ref(false)

const inviteVisible = ref(false)
const inviteToken = ref()

const inviteTooltipVisible = ref(false)

const handleInviteVisible = (visible: boolean) => {
  if (isPersonalOrg.value) return
  popoverVisible.value = false
  inviteVisible.value = visible
  if (visible) {
    generateInvitedToken().then((res: any) => {
      inviteToken.value = res
    })
  }
}

function goHome() {
  router.push('/')
}

function handleMenuClick(info: MenuInfo) {
  switch (info.key) {
    case 'logout':
      logout()
      break
    default:
      break
  }
}

function logout() {
  userStore.logout().then(() => {
    router.push('/login')
    const ss = useSpaceStore()
    ss.orgSpaceList = []
    ss.orgFolderList = []
    ss.orgProjectList = []
  })
}

const goPersonalSettings = () => {
  router.push('/settings/settings')
  popoverVisible.value = false
}

// onMounted(() => {
//   listOrganization().then((data = []) => {
//     setOrganization(data)
//   })
// })

const createFormRef = ref()
const createVisible = ref(false)
const createForm = ref<IKeywords>({})
const createDefs = reactive<IFormItem[]>([
  {
    key: 'name',
    label: '企业名称',
    placeholder: '请输入企业名称',
    type: 'Input',
    rules: [
      {
        required: true,
        message: '请输入',
      },
    ],
  },
  {
    key: 'name',
    label: '你的昵称',
    placeholder: '请输入昵称',
    type: 'Input',
    rules: [
      {
        required: true,
        message: '请输入',
      },
    ],
  },
  {
    key: 'name',
    label: '规模',
    placeholder: '请选择规模',
    type: 'Select',
  },
  {
    key: 'name',
    label: '行业',
    placeholder: '请选择行业',
    type: 'Select',
  },
])
const openCreateModal = () => {
  popoverVisible.value = false
  organizationPopoverVisible.value = false
  createVisible.value = true
}
const handleOkCreate = () => {
  // createFormRef.value.validate().then(() => { })

  createVisible.value = false
}
</script>

<style>
.right-popover {
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-content {
    width: 285px;

    .content {
      margin-top: 20px;

      .item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        height: 20px;
        cursor: pointer;

        .item-first {
          font-size: 14px;
          font-weight: 600;
          margin-left: 16px;
        }

        .item-content {
          font-size: 13px;
        }

        &.disabled {
          color: #00000040;
        }
      }
    }
  }
}
</style>
<style lang="less" scoped>
.user-bar {
  height: 60px;
  padding: 8px 16px;
  border-bottom: 1px solid hsla(240, 3%, 86%, 0.6);
  background-color: white;

  .avatar {
    width: 32px;
    height: 32px;
    background-color: #f0f0f0;
    overflow: hidden;
    cursor: pointer;
    user-select: none;
  }
}
</style>
