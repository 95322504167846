<template>
  <Modal
    maskClosable
    :title="t('pages.platform.user.staffInvite.title')"
    v-model:visible="inviteVisible"
    :width="532"
    :footer="null"
  >
    <div class="invite-item">
      <div :class="tabType === 'link' ? 'change-item' : 'item'" @click="changeInviteMode('link')">
        链接邀请
      </div>
      <div :class="tabType === 'phone' ? 'change-item' : 'item'" @click="changeInviteMode('phone')">
        手机号邀请
      </div>
    </div>
    <template v-if="tabType === 'link'">
      <div class="input-content">
        <div class="content-item">
          <a-input readonly :value="inviteUrl" />
        </div>
        <a-button type="primary" class="content-button" @click="handleCopy">
          {{ t('action.copy') }}
        </a-button>
      </div>
      <div class="invite-text">将链接发送给目标用户,点击即可加入企业</div>
    </template>
    <template v-else>
      <div>
        <a-row v-for="(item, index) in phoneList.list" :key="index" class="phone-item">
          <a-col :span="22">
            <a-input placeholder="请输入手机号" v-model:value="item.phone"
          /></a-col>
          <a-col :span="2">
            <Icon
              code="xe718;"
              style="margin-left: 12px; transform: translateX(3px); cursor: pointer"
              :size="16"
              @click="deletePhone(index)"
            >
            </Icon>
          </a-col>
        </a-row>
        <div class="invite-bottom">
          <div>
            <a @click="addPhone">+继续添加</a>
          </div>
          <div>
            <a-button @click="changeInviteMode('link')" type="primary">发送邀请</a-button>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>
<script setup lang="ts">
import { Modal } from '@elecmap/elecmap-ui'
import { message } from 'ant-design-vue'
import { reactive, ref, toRefs, watch, toRaw } from 'vue'
import { queryInviteOrganization } from '@elecmap/api/src/ram'
import { Icon } from '@elecmap/icon'
import i18n from '@/i18n'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  inviteToken: {
    type: String,
  },
})

const emit = defineEmits<{
  (event: 'close'): void
}>()

const copy = (str: string) => {
  const input = document.createElement('textarea')
  document.body.appendChild(input)
  input.value = str
  input.select()
  document.execCommand('Copy')
  document.body.removeChild(input)
}
const { t } = i18n.global

const inviteVisible = ref(false)
const token = ref()
const inviteUrl = ref()
const organizationNames = ref()

const tabType = ref('link')

const phoneList = reactive<{ list: any[] }>({
  list: [],
})

const changeInviteMode = (mode: string) => {
  tabType.value = mode
}

const deletePhone = (index: number) => {
  if (phoneList.list.length > 1) {
    phoneList.list.splice(index, 1)
  } else {
  }
}

const addPhone = () => {
  phoneList.list.push({
    id: phoneList.list.length + 1,
    phone: '',
  })
}

watch(props, newProps => {
  if (newProps.visible) {
    inviteVisible.value = true
  } else {
    inviteVisible.value = false
    emit('close')
  }
})

watch(inviteVisible, newVisible => {
  if (!newVisible) {
    emit('close')
  }
})

watch(
  () => props.inviteToken,
  newToken => {
    token.value = newToken
    const url = window.location.href
    const [domainName, ...b] = url.split('/#')
    inviteUrl.value = `${domainName}/#/invite?invitedToken=${token.value}`
    queryInviteOrganization(token.value).then((res: any) => {
      const { organizationName } = res
      organizationNames.value = organizationName
    })
  },
)

const handleCopy = () => {
  copy(
    `${t('pages.platform.user.staff.invite.link.hint', {
      organizationName: organizationNames.value,
    })} ${inviteUrl.value}`,
  )
  message.success(t('message.copiedToClipboard', { field: t('field.link') }))
}
</script>

<style scoped lang="scss">
.invite-item {
  display: flex;
  margin-bottom: 24px;

  .item {
    font-size: 14px;
    margin-right: 20px;
    cursor: pointer;
  }

  .change-item {
    font-size: 14px;
    color: rgb(0, 112, 221);
    border-bottom: 3px rgb(0, 112, 221) solid;
    padding-bottom: 8px;
    margin-right: 20px;
  }
}

.input-content {
  display: flex;
  justify-content: center;
  align-items: center;

  .content-item {
    width: 100%;
  }

  .content-button {
    margin-left: 20px;
  }
}

.invite-text {
  margin-top: 24px;
  color: #aaa;
}

.phone-item {
  margin-bottom: 20px;
}

.invite-bottom {
  display: flex;
  justify-content: space-between;
}
</style>
