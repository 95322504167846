<template>
  <div class="invite-button" :class="{ blue }">
    <div class="item-avatar">
      <Icon code="xe643;" :gutter="4" :size="16"> </Icon>
    </div>
    <div class="item-content" @click="handleInviteVisible(true)">邀请成员</div>
    <InviteModal
      :visible="inviteVisible"
      @close="handleInviteVisible(false)"
      :inviteToken="inviteToken"
    />
  </div>
</template>

<script setup lang="ts">
import { Icon } from '@elecmap/icon'
import { ref } from 'vue'
import { generateInvitedToken } from '@elecmap/api/src/ram'
import InviteModal from '../views/member-manage/invite-modal.vue'

defineProps<{
  blue?: boolean
}>()

const popoverVisible = ref(false)

const inviteVisible = ref(false)
const inviteToken = ref()

const handleInviteVisible = (visible: boolean) => {
  popoverVisible.value = false
  inviteVisible.value = visible
  if (visible) {
    generateInvitedToken().then((res: any) => {
      inviteToken.value = res
    })
  }
}
</script>

<style lang="less" scoped>
.invite-button {
  display: flex;
  align-items: center;
  height: 20px;
  cursor: pointer;
  margin-right: 16px;

  &.blue {
    color: #072dff;
  }
}
</style>
