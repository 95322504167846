import { createApp } from 'vue'
import './style.less'
import './assets/css/tailwind.css'
import Antd from 'ant-design-vue'
import { createPinia } from 'pinia'
import { globalEventListener } from '@elecmap/elecmap-ui/lib/utils/'
import router from './router'
import App from './App.vue'
import 'ant-design-vue/dist/antd.css'
import './tailwind.css'
import '@elecmap/elecmap-ui/lib/index.less'
import PageHeaderWithName from '@/components/PageHeaderWithName'

globalEventListener()

createApp(App).use(Antd).use(createPinia()).use(router).use(PageHeaderWithName).mount('#app')
