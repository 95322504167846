import { h } from 'vue'
import Icon from './components/Icon.vue'
import icons from './icons'

interface RenderOptions {
  code?: string
  type?: keyof typeof icons
  size?: number
  color?: string
  rounded?: boolean
  label?: string
  title?: string
  gutter?: number
  fontSize?: number
}

function renderIcon(options?: RenderOptions) {
  let opts = options
  if (!opts) opts = { type: 'empty' }
  if (!opts.type && !opts.code) opts.type = 'empty'
  return () => h(Icon, { gutter: 0, fontSize: 12, ...opts })
}

export { Icon, icons, renderIcon }
