<template>
  <div
    :title="title"
    :name="props.type"
    class="icon-wrapper em-icon"
    :class="{ 'icon-with-title': props.title }"
  >
    <i v-if="isIconfont" class="iconfont2 icon" v-html="type" :style="style" />
    <img v-else :src="type" :title="title" class="icon" :style="style" />
    <span v-if="label" :style="labelStyle">{{ label }}</span>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import icons from '../icons/index'
import '../iconfont/iconfont.less'

const props = withDefaults(
  defineProps<{
    code?: string
    type?: keyof typeof icons
    size?: number
    color?: string
    rounded?: boolean
    label?: string
    title?: string
    gutter?: number
    fontSize?: number
  }>(),
  {
    size: 16,
    gutter: 8,
    fontSize: 13,
    rounded: false,
  },
)

const isIconfont = computed(() => !!props.code)

const style = computed(() => ({
  fontSize: `${props.size}px`,
  width: isIconfont.value ? 'unset' : `${props.size}px`,
  height: isIconfont.value ? 'unset' : `${props.size}px`,
  borderRadius: props.rounded ? '50%' : 'none',
  color: props.color,
  marginRight: props.label ? 0 : `${props.gutter}px`,
}))

const labelStyle = computed(() => ({
  marginLeft: `${props.gutter}px`,
  fontSize: `${props.fontSize}px`,
}))

const type = computed(() => {
  if (props.code) {
    return `&#${props.code}`
  }
  if (props.type) {
    return icons[props.type]
  }
  return ''
})
</script>

<style lang="less" scoped>
.icon-wrapper,
.icon {
  display: block;
  transition: background 0.3s;
}

.icon-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.icon {
  background: transparent;
}
</style>
