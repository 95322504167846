import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import type { Organization } from '@elecmap/api/src/ram.d'
import { changeOrganization } from '@elecmap/api/src/ram'

export const useOrganization = defineStore('organization', () => {
  let defaultOrganization = []
  let defaultOrganizationId
  let defaultIsPlatform = false
  if (localStorage.getItem('organizationList')) {
    defaultOrganization = JSON.parse(localStorage.getItem('organizationList') || '[]')
  }

  if (localStorage.getItem('organizationId')) {
    defaultOrganizationId = JSON.parse(localStorage.getItem('organizationId') || '0')
  }

  if (localStorage.getItem('isPlatformUser')) {
    defaultIsPlatform = JSON.parse(localStorage.getItem('isPlatformUser') || 'false')
  }

  const organization = ref<Organization[]>(defaultOrganization)
  const organizationId = ref<number>(defaultOrganizationId)
  const isPlatformUser = ref(defaultIsPlatform)
  function setOrganization(list: Organization[]) {
    organization.value = list
    localStorage.setItem('organizationList', JSON.stringify(list))
  }

  const setCurrentOrganizationId = (id: number, organizationType?: string) => {
    organizationId.value = id
    localStorage.setItem('organizationId', String(id))

    const isPlatform = organizationType === 'platform'
    isPlatformUser.value = isPlatform
    localStorage.setItem('isPlatformUser', JSON.stringify(isPlatform))
  }

  const currentOrganization = computed(() => {
    return organization.value.find(o => o.id === organizationId.value)
  })

  function resetToPersonalOrg() {
    organizationId.value = organization.value[0].id
    return changeOrganization(organizationId.value)
  }

  return {
    organization,
    setOrganization,
    organizationId,
    setCurrentOrganizationId,
    isPlatformUser,
    currentOrganization,
    resetToPersonalOrg,
  }
})
