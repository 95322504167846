import canvasGrid from './canvas/canvas-grid.svg'
import arcByPoints from './files/arc-by-points.svg'
import arc from './files/arc.svg'
import bpIdentifier from './files/bp-identifier.svg'
import bpLeft from './files/bp-left.svg'
import bpMarkLetter from './files/bp-mark-letter.svg'
import bpRight from './files/bp-right.svg'
import bpView from './files/bp-view.svg'
import bpView2 from './files/bp-view2.svg'
import catchGrid from './files/catch-grid.svg'
import catchSymbol from './files/catch-symbol.svg'
import circleByPoints from './files/circle-by-points.svg'
import circle from './files/circle.svg'
import collect from './files/collect.svg'
import config from './files/config.svg'
import curve from './files/curve.svg'
import device from './files/device.svg'
import down from './files/down.svg'
import drawings from './files/drawings.svg'
import dynamicText from './files/dynamic-text.svg'
import ellipse from './files/ellipse.svg'
import export1 from './files/export1.svg'
import fitScreen from './files/fit-screen.svg'
import leftTriangle from './files/left-triangle.svg'
import line from './files/line.svg'
import line2 from './files/line2.svg'
import link from './files/link.svg'
import logic from './files/logic.svg'
import macro from './files/macro.svg'
import minus from './files/minus.svg'
import mountingPlate from './files/mounting-plate.svg'
import pictureFrame from './files/picture-frame.svg'
import picture from './files/picture.svg'
import pinDown from './files/pin-down.svg'
import pinLeft from './files/pin-left.svg'
import pinRight from './files/pin-right.svg'
import pinUp from './files/pin-up.svg'
import pinned from './files/pinned.svg'
import plus from './files/plus.svg'
import polygon from './files/polygon.svg'
import polyline from './files/polyline.svg'
import project from './files/project.svg'
import qrCode from './files/qr-code.svg'
import recent from './files/recent.svg'
import rectByPoints from './files/rect-by-points.svg'
import rect from './files/rect.svg'
import redo from './files/redo.svg'
import rightTriangle from './files/right-triangle.svg'
import save from './files/save.svg'
import search from './files/search.svg'
import sector from './files/sector.svg'
import share from './files/share.svg'
import symbol from './files/symbol.svg'
import table from './files/table.svg'
import template from './files/template.svg'
import text from './files/text.svg'
import undo from './files/undo.svg'
import user from './files/user.svg'
import breakPoint from './graphs/break-point.svg'
import crossJoint from './graphs/cross-joint.svg'
import diagonal from './graphs/diagonal.svg'
import higherLeftCorner from './graphs/higher-left-corner.svg'
import higherRightCorner from './graphs/higher-right-corner.svg'
import jumper from './graphs/jumper.svg'
import lowerLeftCorner from './graphs/lower-left-corner.svg'
import lowerRightCorner from './graphs/lower-right-corner.svg'
import tDown from './graphs/t-down.svg'
import tLeft from './graphs/t-left.svg'
import tRight from './graphs/t-right.svg'
import tUp from './graphs/t-up.svg'
import anquanwuyou from './home/anquanwuyou.svg'
import baozhang from './home/baozhang.svg'
import dingzhi from './home/dingzhi.svg'
import wufengjicheng from './home/wufengjicheng.svg'
import xingneng from './home/xingneng.svg'
import zizhukekong from './home/zizhukekong.svg'
import alignCenter from './menu/align-center.svg'
import alignLeft from './menu/align-left.svg'
import alignRight from './menu/align-right.svg'
import analogInput from './menu/analog-input.svg'
import analogOutput from './menu/analog-output.svg'
import anchorBottom from './menu/anchor-bottom.svg'
import anchorMiddle from './menu/anchor-middle.svg'
import anchorTop from './menu/anchor-top.svg'
import back from './menu/back.svg'
import blackBox from './menu/black-box.svg'
import bold from './menu/bold.svg'
import borderRadius from './menu/border-radius.svg'
import bottomLayer from './menu/bottom-layer.svg'
import boxInsert from './menu/box-insert.svg'
import busPort from './menu/bus-port.svg'
import busbarConnectionPoint from './menu/busbar-connection-point.svg'
import cableHarness from './menu/cable-harness.svg'
import cable from './menu/cable.svg'
import cardPower from './menu/card-power.svg'
import chamfer from './menu/chamfer.svg'
import cmClose from './menu/cm-close.svg'
import cmCopy from './menu/cm-copy.svg'
import cmCreatePage from './menu/cm-create-page.svg'
import cmCreateProject from './menu/cm-create-project.svg'
import cmDelete from './menu/cm-delete.svg'
import cmEdit from './menu/cm-edit.svg'
import cmExport from './menu/cm-export.svg'
import cmMove from './menu/cm-move.svg'
import cmOpen from './menu/cm-open.svg'
import cmPermission from './menu/cm-permission.svg'
import cmProperty from './menu/cm-property.svg'
import cmRename from './menu/cm-rename.svg'
import cmShare from './menu/cm-share.svg'
import cmStart from './menu/cm-start.svg'
import connectionInsert from './menu/connection-insert.svg'
import connectionPointPower from './menu/connection-point-power.svg'
import copy from './menu/copy.svg'
import crossLink from './menu/cross-link.svg'
import cut from './menu/cut.svg'
import deviceConnectionPointsBoth from './menu/device-connection-points-both.svg'
import deviceConnectionPoints from './menu/device-connection-points.svg'
import deviceList from './menu/device-list.svg'
import digitalInput from './menu/digital-input.svg'
import digitalOutput from './menu/digital-output.svg'
import distributedTerminals from './menu/distributed-terminals.svg'
import electricCable from './menu/electric-cable.svg'
import electricityTerminal from './menu/electricity-terminal.svg'
import fillColor from './menu/fill-color.svg'
import fixed from './menu/fixed.svg'
import flip from './menu/flip.svg'
import fontColor from './menu/font-color.svg'
import font from './menu/font.svg'
import formatBrush from './menu/format-brush.svg'
import group from './menu/group.svg'
import horizontalCenter from './menu/horizontal-center.svg'
import horizontalDistribution from './menu/horizontal-distribution.svg'
import insertionPoint from './menu/insertion-point.svg'
import italic from './menu/italic.svg'
import lineColor from './menu/line-color.svg'
import lineHeight from './menu/line-height.svg'
import lineStyle from './menu/line-style.svg'
import lineWidth from './menu/line-width.svg'
import logout from './menu/logout.svg'
import materialList from './menu/material-list.svg'
import multiCopy from './menu/multi-copy.svg'
import networkDef from './menu/network-def.svg'
import paste from './menu/paste.svg'
import path from './menu/path.svg'
import phaseBus from './menu/phase-bus.svg'
import pinInsert from './menu/pin-insert.svg'
import plc from './menu/plc.svg'
import plugDef from './menu/plug-def.svg'
import plug from './menu/plug.svg'
import potentialConnection from './menu/potential-connection.svg'
import potentialDef from './menu/potential-def.svg'
import potential from './menu/potential.svg'
import projectStructure from './menu/project-structure.svg'
import propertySettings from './menu/property-settings.svg'
import pruning from './menu/pruning.svg'
import rowWrap from './menu/row-wrap.svg'
import scale from './menu/scale.svg'
import setting from './menu/setting.svg'
import shieldInsert from './menu/shield-insert.svg'
import structureBox from './menu/structure-box.svg'
import structure from './menu/structure.svg'
import tableEdit from './menu/table-edit.svg'
import terminalStripDef from './menu/terminal-strip-def.svg'
import terminal from './menu/terminal.svg'
import toolCheck from './menu/tool-check.svg'
import toolGenerateTable from './menu/tool-generate-table.svg'
import toolNotification from './menu/tool-notification.svg'
import toolPotentialTrack from './menu/tool-potential-track.svg'
import toolSignal from './menu/tool-signal.svg'
import toolSyncTable from './menu/tool-sync-table.svg'
import toolSync from './menu/tool-sync.svg'
import toolUpdate from './menu/tool-update.svg'
import toolbarBreakPoint from './menu/toolbar-break-point.svg'
import toolbarDevice from './menu/toolbar-device.svg'
import toolbarLink from './menu/toolbar-link.svg'
import toolbarMacro from './menu/toolbar-macro.svg'
import toolbarPictureFrame from './menu/toolbar-picture-frame.svg'
import toolbarPreview from './menu/toolbar-preview.svg'
import toolbarSymbol from './menu/toolbar-symbol.svg'
import topLayer from './menu/top-layer.svg'
import underline from './menu/underline.svg'
import ungroup from './menu/ungroup.svg'
import verticalCenter from './menu/vertical-center.svg'
import verticalDistribution from './menu/vertical-distribution.svg'
import tabDrawPlatform from './tabs/tab-draw-platform.svg'
import tabDraw from './tabs/tab-draw.svg'
import tabFramePlatform from './tabs/tab-frame-platform.svg'
import tabFrame from './tabs/tab-frame.svg'
import tabMacroPlatform from './tabs/tab-macro-platform.svg'
import tabMacro from './tabs/tab-macro.svg'
import tabSymbolPlatform from './tabs/tab-symbol-platform.svg'
import tabSymbol from './tabs/tab-symbol.svg'
import tabTablePlatform from './tabs/tab-table-platform.svg'
import tabTable from './tabs/tab-table.svg'
import add from './ui/add.svg'
import alignmark from './ui/alignmark.svg'
import angelmark from './ui/angelmark.svg'
import baselinemark from './ui/baselinemark.svg'
import continuousmark from './ui/continuousmark.svg'
import deviceListNav from './ui/device-list-nav.svg'
import deviceNav from './ui/device-nav.svg'
import dockDisabled from './ui/dock-disabled.svg'
import docked from './ui/docked.svg'
import dockingDown from './ui/docking-down.svg'
import dockingLeft from './ui/docking-left.svg'
import dockingRight from './ui/docking-right.svg'
import dockingUp from './ui/docking-up.svg'
import empty from './ui/empty.svg'
import home from './ui/home.svg'
import incrementmark from './ui/incrementmark.svg'
import layoutPage from './ui/layout-page.svg'
import linemark from './ui/linemark.svg'
import linkNav from './ui/link-nav.svg'
import macroVariant from './ui/macro-variant.svg'
import macroVariant2 from './ui/macro-variant2.svg'
import macroVariant3 from './ui/macro-variant3.svg'
import macroVariant4 from './ui/macro-variant4.svg'
import more from './ui/more.svg'
import page from './ui/page.svg'
import pinInsertNav from './ui/pin-insert-nav.svg'
import plugNav from './ui/plug-nav.svg'
import plusOutline from './ui/plus-outline.svg'
import potentialNav from './ui/potential-nav.svg'
import potetialTree from './ui/potetial-tree.svg'
import radiusmark from './ui/radiusmark.svg'
import remove from './ui/remove.svg'
import structureAnd from './ui/structure-and.svg'
import structureDbis from './ui/structure-dbis.svg'
import structureDbplus from './ui/structure-dbplus.svg'
import structureIs from './ui/structure-is.svg'
import structurePlus from './ui/structure-plus.svg'
import tablePage from './ui/table-page.svg'
import terminalNav from './ui/terminal-nav.svg'
import treeConnection from './ui/tree-connection.svg'
import treePlug from './ui/tree-plug.svg'
import treePotential from './ui/tree-potential.svg'
import treeTerminal from './ui/tree-terminal.svg'
import undocked from './ui/undocked.svg'

export default {
  canvasGrid,
  arcByPoints,
  arc,
  bpIdentifier,
  bpLeft,
  bpMarkLetter,
  bpRight,
  bpView,
  bpView2,
  catchGrid,
  catchSymbol,
  circleByPoints,
  circle,
  collect,
  config,
  curve,
  device,
  down,
  drawings,
  dynamicText,
  ellipse,
  export1,
  fitScreen,
  leftTriangle,
  line,
  line2,
  link,
  logic,
  macro,
  minus,
  mountingPlate,
  pictureFrame,
  picture,
  pinDown,
  pinLeft,
  pinRight,
  pinUp,
  pinned,
  plus,
  polygon,
  polyline,
  project,
  qrCode,
  recent,
  rectByPoints,
  rect,
  redo,
  rightTriangle,
  save,
  search,
  sector,
  share,
  symbol,
  table,
  template,
  text,
  undo,
  user,
  breakPoint,
  crossJoint,
  diagonal,
  higherLeftCorner,
  higherRightCorner,
  jumper,
  lowerLeftCorner,
  lowerRightCorner,
  tDown,
  tLeft,
  tRight,
  tUp,
  anquanwuyou,
  baozhang,
  dingzhi,
  wufengjicheng,
  xingneng,
  zizhukekong,
  alignCenter,
  alignLeft,
  alignRight,
  analogInput,
  analogOutput,
  anchorBottom,
  anchorMiddle,
  anchorTop,
  back,
  blackBox,
  bold,
  borderRadius,
  bottomLayer,
  boxInsert,
  busPort,
  busbarConnectionPoint,
  cableHarness,
  cable,
  cardPower,
  chamfer,
  cmClose,
  cmCopy,
  cmCreatePage,
  cmCreateProject,
  cmDelete,
  cmEdit,
  cmExport,
  cmMove,
  cmOpen,
  cmPermission,
  cmProperty,
  cmRename,
  cmShare,
  cmStart,
  connectionInsert,
  connectionPointPower,
  copy,
  crossLink,
  cut,
  deviceConnectionPointsBoth,
  deviceConnectionPoints,
  deviceList,
  digitalInput,
  digitalOutput,
  distributedTerminals,
  electricCable,
  electricityTerminal,
  fillColor,
  fixed,
  flip,
  fontColor,
  font,
  formatBrush,
  group,
  horizontalCenter,
  horizontalDistribution,
  insertionPoint,
  italic,
  lineColor,
  lineHeight,
  lineStyle,
  lineWidth,
  logout,
  materialList,
  multiCopy,
  networkDef,
  paste,
  path,
  phaseBus,
  pinInsert,
  plc,
  plugDef,
  plug,
  potentialConnection,
  potentialDef,
  potential,
  projectStructure,
  propertySettings,
  pruning,
  rowWrap,
  scale,
  setting,
  shieldInsert,
  structureBox,
  structure,
  tableEdit,
  terminalStripDef,
  terminal,
  toolCheck,
  toolGenerateTable,
  toolNotification,
  toolPotentialTrack,
  toolSignal,
  toolSyncTable,
  toolSync,
  toolUpdate,
  toolbarBreakPoint,
  toolbarDevice,
  toolbarLink,
  toolbarMacro,
  toolbarPictureFrame,
  toolbarPreview,
  toolbarSymbol,
  topLayer,
  underline,
  ungroup,
  verticalCenter,
  verticalDistribution,
  tabDrawPlatform,
  tabDraw,
  tabFramePlatform,
  tabFrame,
  tabMacroPlatform,
  tabMacro,
  tabSymbolPlatform,
  tabSymbol,
  tabTablePlatform,
  tabTable,
  add,
  alignmark,
  angelmark,
  baselinemark,
  continuousmark,
  deviceListNav,
  deviceNav,
  dockDisabled,
  docked,
  dockingDown,
  dockingLeft,
  dockingRight,
  dockingUp,
  empty,
  home,
  incrementmark,
  layoutPage,
  linemark,
  linkNav,
  macroVariant,
  macroVariant2,
  macroVariant3,
  macroVariant4,
  more,
  page,
  pinInsertNav,
  plugNav,
  plusOutline,
  potentialNav,
  potetialTree,
  radiusmark,
  remove,
  structureAnd,
  structureDbis,
  structureDbplus,
  structureIs,
  structurePlus,
  tablePage,
  terminalNav,
  treeConnection,
  treePlug,
  treePotential,
  treeTerminal,
  undocked,
} as Record<string, string>
