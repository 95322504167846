<template>
  <Modal :title="title" @open="onOpen" @ok="handleOk" wrapClassName="folder-selector-modal">
    <a-tree
      v-if="treeData.length"
      :field-names="{ key: 'id', title: 'name', children: 'children' }"
      defaultExpandAll
      v-model:selectedKeys="selectedKeys"
      :tree-data="treeData"
    ></a-tree>
  </Modal>
</template>

<script setup lang="ts">
import { Modal } from '@elecmap/elecmap-ui'
import { ref } from 'vue'
import { listOrganizationFolderTree } from '@elecmap/api/src/cad/space'
import type { ListFolderTreeDto } from '@elecmap/api/src/cad/space.d'

const props = defineProps<{
  title: string
  locationId?: number
}>()

const emit = defineEmits(['ok'])

const treeData = ref<ListFolderTreeDto[]>([])
const selectedKeys = ref<number[]>([])

function onOpen() {
  selectedKeys.value = []
  listOrganizationFolderTree().then(data => {
    treeData.value = data
    if (props.locationId) {
      selectedKeys.value = [props.locationId]
    }
  })
}

function handleOk() {
  emit('ok', selectedKeys.value[0])
}
</script>

<style lang="less">
.folder-selector-modal {
  .ant-modal-body {
    height: 50vh !important;
  }
}
</style>
