import type { CadFolderShortVo } from '@elecmap/api/src/cad/space.d'
import { defineStore } from 'pinia'
import { ref } from 'vue'

const usePersonalSpaceStore = defineStore('PersonalSpace', () => {
  const navs = ref<CadFolderShortVo[]>([])

  return {
    navs,
  }
})

export default usePersonalSpaceStore
