import { ref, toRaw, isProxy, isRef, computed, watch } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { useRoute, useRouter } from 'vue-router'
import { getState, setState } from '@elecmap/elecmap-ui'

export interface MenuAsideNavData {
  id: number
  parentId: number
  menuName: string
  selected: boolean
  url: string
  icon: string
  urlFilterRegex: null | string
  menu: null | string
  rowSequence: null | number
  columnSequence: null | number
  code: string
  level: number
  category: null | string
  children: MenuAsideNavData[]
  show?: boolean
  active?: boolean
  asideTips?: boolean
  asideTipsNum?: string
  blockType: '0' | '1' | '2'
  subMenuType: '0' | '1'
  stateName: '0' | '1'
  stateId: '0' | '1'
}

export interface NavTab {
  name?: string
  path: string
  query?: string
}

export interface NavTabStore {
  organizationId: number
  data: NavTab[]
}

export const useMenuData = defineStore('menuData', () => {
  const data = ref<MenuAsideNavData[]>([])
  const setData = (re: MenuAsideNavData[]) => {
    data.value = re
  }

  return { data, setData }
})

export const useNavTab = defineStore('navTab', () => {
  const route = useRoute()
  const router = useRouter()
  const menuData = storeToRefs(useMenuData())

  const traverse = (path: string, data: MenuAsideNavData[] = menuData.data.value): string => {
    for (let i = 0; i < data.length; i += 1) {
      if (path === data[i].url) return data[i].menuName
      if (data[i].children.length > 0) {
        const childrenResult = traverse(path, data[i].children)
        if (childrenResult !== '') return childrenResult
      }
    }
    return ''
  }
  const findNameByPath = (
    path: string,
    data: MenuAsideNavData[] = menuData.data.value,
  ): string | undefined => {
    let [purePath] = path.split('?')
    const traverseResult = traverse(purePath, data)
    if (traverseResult) return traverseResult
    if (purePath.startsWith('/#/')) purePath = purePath.slice(2)
    const match = router.getRoutes().find(r => r.path === purePath)
    return match && match.meta && (match.meta.title as string)
  }

  const isHash = window.location.hash.startsWith('#/')
  let base = ''
  if (isHash) base = '/#'
  let defaultTabs: NavTabStore = {
    organizationId: JSON.parse(localStorage.getItem('organizationId') as string) || '',
    data: [],
  }
  const storeTabs = getState('navTab')

  if (storeTabs) {
    defaultTabs = storeTabs
  }

  const saveToStore = (payload: NavTabStore) => {
    let data = payload
    if (isProxy(data)) {
      data = toRaw(data)
    }

    if (isRef(data)) {
      data = toRaw(data.value) as NavTabStore
    }

    setState('navTab', data || {})
  }

  const tabs = ref<NavTabStore>(defaultTabs)

  const current = ref(tabs.value.data.find(d => route.fullPath === d.path) ?? { path: route.path })
  const pushTab = (payload: NavTab, replace?: boolean) => {
    const { path, name } = payload
    const { data } = tabs.value
    const navTab: NavTab = { path, name }
    let { query } = payload
    let targetPath: string = navTab.path
    if (path.split('?').length > 1) {
      ;[targetPath, query] = navTab.path.split('?')
    }
    navTab.path = targetPath
    if (!name) {
      navTab.name = findNameByPath(targetPath)
    }
    if (targetPath.startsWith('/#')) {
      navTab.path = targetPath.replace('/#', '')
    }
    navTab.query = query

    const index = data.findIndex(d => d.path === navTab.path)
    if (index === -1) {
      data.push(navTab)
    } else {
      data[index] = navTab
    }

    current.value = navTab

    saveToStore(tabs.value)

    const pathTo = navTab.query ? `${base + navTab.path}?${navTab.query}` : base + navTab.path

    if (replace) {
      window.location.replace(pathTo)
      return
    }
    window.location.href = pathTo
  }

  const removeTab = (path: string) => {
    const { data } = tabs.value
    const currentPath = current?.value?.path
    tabs.value.data = data.filter(d => d.path !== path)

    saveToStore(tabs.value)

    if (currentPath === path) {
      const latestData = tabs.value.data
      const next = latestData[latestData.length - 1]

      current.value = next

      window.location.replace(base + next.path)
    }
  }

  const replaceTab = (payload: NavTab, replace?: boolean) => {
    const { data } = tabs.value
    const currentPath = current?.value?.path
    tabs.value.data = data.filter(d => d.path !== currentPath)

    pushTab(payload, replace)
  }

  const clearStore = () => {
    tabs.value = {
      organizationId: JSON.parse(localStorage.getItem('organizationId') || ''),
      data: [],
    }
  }

  const getPath = () => {
    const { pathname, hash } = window.location
    // let path = pathname + hash
    // if (path.indexOf('?') !== -1) {
    //   ;[path] = path.split('?')
    // }
    return pathname + hash
  }

  const currentName = computed(() => {
    const path = getPath()

    return findNameByPath(path)
  })

  watch(
    [() => route.fullPath, () => menuData],
    ([nextPath], [previousPath]) => {
      if (
        nextPath === '/login' ||
        nextPath.startsWith('/organization') ||
        localStorage.getItem('refresh')
      ) {
        localStorage.removeItem('refresh')
        return
      }
      let query = ''
      let path = getPath()
      if (path.split('?').length > 1) {
        ;[path, query] = path.split('?')
      }
      const pathNoHash = path.slice(2)
      const re = tabs.value.data.find(d => d.path === pathNoHash)

      if (!re || (nextPath === previousPath && re.query !== query) || menuData.data.value.length) {
        pushTab({ path, query })
      }
    },
    { immediate: true },
  )

  return { tabs, pushTab, removeTab, replaceTab, current, currentName, clearStore }
})
